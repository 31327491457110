<template>
  <section class="contact">
    <h2 class="sub-heading sub-heading--white">
      {{ $t("heading.contact") }}
    </h2>

    <b-nav vertical>
      <b-nav-text v-for="(item, index) in listItems" :key="index">
        <i
          v-if="item.value !== null"
          class="contact__icon"
          :class="`icon-${item.icon}`"
        ></i>
        <span v-if="item.value !== null">
          {{ item.value }}
        </span>
      </b-nav-text>
    </b-nav>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ContactList",
  data() {
    return {
      listItems: [],
      locations: []
    };
  },
  computed: {
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.listItems = this.settings("contacts");
        // const location_1 = this.settings("global_contact_location_1");
        const location_2 = this.settings("global_contact_location_2");
        this.locations = [
          { icon: " icon-addresse", value: location_2 }
          // { icon: " icon-addresse", value: location_1 }
        ];
        if (this.locations.length && this.listItems) {
          this.listItems = [...this.locations, ...this.listItems];
        }
      },
      immediate: true
    }
  }
};
</script>
